import React from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { checkEmail, checkUsername } from 'repository/accessForm/AccessFormRepository';
import { validateIdentificationNumber } from 'util/validation';

export const PersonalDataStep = ({ formFields, onFormFieldsChange, nextStep }) => {
  const onFinish = async (values) => {
    try {
      await checkEmail(values.email, 'vic');
      await checkUsername(values.cardId, 'vic');
      nextStep();
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: <div>{error.message}</div>,
        okText: 'Ok',
        centered: true,
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Error en el formulari: ', errorInfo);
  };

  const formStyle = {
    width: '100%',
    maxWidth: '500px',
  };

  return (
    <Form
      layout={'vertical'}
      name="personal-data-form"
      style={formStyle}
      fields={formFields}
      onFieldsChange={(_, allFields) => {
        onFormFieldsChange(allFields);
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Número del document d'identificació (DNI, passaport, NIE ...)"
        name="cardId"
        rules={[
          {
            required: true,
            message: 'Si us plau, introduïu el DNI, NIF, NIE, CIF o passaport',
          },
          () => ({
            validator(_, value) {
              if (validateIdentificationNumber(value)) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("El document d'identificació no segueix el patró de DNI, NIF, NIE, CIF o passaport")
              );
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Nom"
        name="name"
        rules={[
          {
            required: true,
            message: 'Si us plau, introduïu el nom',
          },
          {
            min: 2,
            message: 'El nom ha de tenir com a mínim 2 caràcters',
          },
          {
            max: 30,
            message: 'El nom ha de tenir com a màxim 30 caràcters',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Cognoms"
        name="surnames"
        rules={[
          {
            required: true,
            message: 'Si us plau, introduïu els cognoms',
          },
          {
            min: 2,
            message: 'Els cognoms han de tenir com a mínim 2 caràcters',
          },
          {
            max: 60,
            message: 'Els cognoms han de tenir com a màxim 60 caràcters',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Correu electrònic"
        name="email"
        rules={[
          {
            required: true,
            message: 'Si us plau, introduïu el correu electrònic',
          },
          {
            pattern: new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
            message: 'No és un correu electrònic vàlid',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Repetiu el correu electrònic"
        name="confirmEmail"
        rules={[
          {
            required: true,
            message: 'Si us plau, introduïu el correu electrònic una altra vegada',
          },
          {
            pattern: new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
            message: 'No és un correu electrònic vàlid',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('email') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Els correus electrònics no coincideixen'));
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Telèfon mòbil"
        name="phone"
        rules={[
          {
            pattern: new RegExp(/^\d+$/),
            message: 'No és un telèfon vàlid',
          },
          {
            max: 15,
            message: 'El telèfon mòbil és massa llarg',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Repetiu el telèfon mòbil"
        name="confirmPhone"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!getFieldValue('phone') || getFieldValue('phone') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Els telèfons no coincideixen'));
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ float: 'right', margin: '5px' }}
        >
          Següent
        </Button>
      </Form.Item>
    </Form>
  );
};

import React, { useState } from 'react';
import backgroundImage from 'assets/img/orgtconnect-background.jpg';
import { v4 as uuid4 } from 'uuid';
import moment from 'moment';

import { Modal, Card, Steps } from 'antd';
import { PersonalDataStep } from './PersonalDataStep';
import { ZonesStep } from './ZonesStep';
import { LicensePlatesStep } from './LicensePlatesStep';
import { UserVerificationStep } from './UserVerificationStep';
import { ReasonsEnum, ZonesEnum } from './constants';
import {
  postCitizen,
  postZoneAuthorizations,
  postWhitelistEntries,
  postFormData,
  sendMailToNotifyUserForm,
} from 'repository/accessForm/AccessFormRepository';

const CitizenForm = ({ location, history }) => {
  const isSinglePage = location.state?.singlePage || false;
  const username = location.state?.username || false;
  const authorizedZones = location.state?.authorizedZones || [];
  const [currentStep, setCurrentStep] = useState(location.state?.initialStep || 0);

  const [personalDataFields, setPersonalDataFields] = useState([
    { name: ['cardId'], value: '' },
    { name: ['name'], value: '' },
    { name: ['surnames'], value: '' },
    { name: ['email'], value: '' },
    { name: ['phone'], value: '' },
  ]);
  const [zonesFields, setZonesFields] = useState({
    accessReason: undefined,
    selectedZones: [],
    attachedFile: undefined,
  });
  const [licensePlatesFields, setLicensePlatesFields] = useState({
    enableFormValue: undefined,
    plateFields: [],
    agreement: undefined,
  });

  const submitFinalData = async () => {
    const user = {
      id: uuid4(),
      username: personalDataFields[0].value,
      name: personalDataFields[1].value,
      surnames: personalDataFields[2].value,
      email: personalDataFields[3].value,
      phone: personalDataFields[5].value,
      reducedMobility: zonesFields.accessReason === ReasonsEnum.MOBILITAT_REDUIDA,
    };
    const finalUser = trimStringFields(user);

    const zones = {
      username: user.username,
      accessReason: zonesFields.accessReason,
      selectedZones: zonesFields.selectedZones,
      attachedFile: zonesFields.attachedFile,
    };
    const finalZones = trimStringFields(zones);

    const licensePlates = {
      username: user.username,
      plateFields: mapLicensePlateFields(licensePlatesFields),
    };
    const finalLicensePlates = trimStringFields(licensePlates);

    const form = {
      DNI: user.username,
      name: user.name,
      surnames: user.surnames,
      email: user.email,
      phone: user.phone,
      accessReason: zones.accessReason,
      zones: zones.selectedZones.map((zone) => {
        return { id: zone, name: getZoneName(zone) };
      }),
      zoneAttachedFileName: zonesFields.attachedFile?.name,
      licensePlates: mapLicensePlateFields(licensePlatesFields),
    };
    const finalForm = trimStringFields(form);
    let licensePlateFiles = licensePlatesFields.plateFields.map((field) => field.attachedFile);

    const repeatedFileNames = checkFileNames();
    if (repeatedFileNames === true) {
      Modal.error({
        title: 'Error',
        content: <div>Hi ha fitxers amb el mateix nom. Revisa les dades un altre cop.</div>,
        okText: 'Ok',
        onOk: () => setCurrentStep(0),
        centered: true,
      });
      return;
    }

    const timestamp = moment().format('YYYY-MM-DD HH:mm:ss').toString();

    console.log(finalZones, finalLicensePlates, finalForm, zones.attachedFile, licensePlateFiles);

    // Create user, zones authorizations, whitelist-entries and send final email
    try {
      await postCitizen(finalUser, 'vic');
      await postZoneAuthorizations(finalZones, timestamp, 'vic');
      await postWhitelistEntries(finalLicensePlates, timestamp, null, 'vic');
      await postFormData(finalForm, zones.attachedFile, licensePlateFiles, timestamp);

      Modal.info({
        title: 'Formulari enviat correctament',
        content: (
          <div>
            S'ha enviat el formulari correctament. Podeu consultar les dades al lloc web. S'ha enviat un correu
            electrònic amb les credencials per accedir-hi.
          </div>
        ),
        okText: 'Acceptar',
        onOk: () => history.push('/login/vic'),
        centered: true,
      });

      // Send email notification to user
      sendMailToNotifyUserForm(user.email, 'vic');
    } catch (error) {
      console.error('An error occurred:', error);
      showErrorModal();
    }
  };

  const getZoneName = (zoneId) => {
    const zone = Object.values(ZonesEnum).find((zone) => zone.id === zoneId);
    return zone.name;
  };

  function mapLicensePlateFields(licensePlatesFormFields) {
    return licensePlatesFormFields.plateFields.map((field) => {
      return {
        plate: field.plate,
        relationship: field.relationship,
        attachedFileName: field.attachedFile?.name,
      };
    });
  }

  function trimStringFields(obj) {
    const newObj = { ...obj };

    for (const key in newObj) {
      if (typeof newObj[key] === 'string') {
        newObj[key] = newObj[key].trim();
      }
    }

    return newObj;
  }

  function checkFileNames() {
    const singleFile = zonesFields.attachedFile;
    let allFiles = licensePlatesFields.plateFields.map((field) => field.attachedFile);
    allFiles.push(singleFile);

    const fileNames = new Set();

    for (const file of allFiles) {
      if (file instanceof File) {
        if (fileNames.has(file.name)) {
          return true;
        }
        fileNames.add(file.name);
      }
    }

    return false;
  }

  function showErrorModal() {
    Modal.error({
      title: 'Error',
      content: <div>Hi ha hagut un error en l'enviament del formulari.</div>,
      okText: 'Ok',
      onOk: () => history.push('/login/vic'),
      centered: true,
    });
  }

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderCardTitle = () => {
    return (
      <div style={{ textAlign: 'center', fontSize: '2rem', margin: '10px' }}>Petició d'accés a l'illa de vianants</div>
    );
  };

  const steps = [
    {
      title: 'Indiqueu les dades personals',
      content: (
        <PersonalDataStep
          formFields={personalDataFields}
          onFormFieldsChange={(newFields) => setPersonalDataFields(newFields)}
          nextStep={nextStep}
        />
      ),
    },
    {
      title: "Indiqueu la zona d'accés",
      content: (
        <ZonesStep
          initialFormFields={zonesFields}
          onFormFieldsChange={(newFields) => setZonesFields(newFields)}
          nextStep={nextStep}
          prevStep={prevStep}
          singlePage={isSinglePage}
          username={username}
          history={history}
        />
      ),
    },
    {
      title: 'Indiqueu les matrícules',
      content: (
        <LicensePlatesStep
          initialFormFields={licensePlatesFields}
          onFormFieldsChange={(newFields) => {
            setLicensePlatesFields(newFields);
          }}
          nextStep={nextStep}
          prevStep={prevStep}
          singlePage={isSinglePage}
          username={username}
          authorizedZones={authorizedZones}
          selectedZoneReason={zonesFields.accessReason}
          history={history}
        />
      ),
    },
    {
      title: "Verificació d'usuari",
      content: (
        <UserVerificationStep
          userEmail={personalDataFields[3].value}
          finishForm={submitFinalData}
        />
      ),
    },
  ];

  const stepItems = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const outerWrapperStyle = {
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `linear-gradient(rgba(30, 30, 30, 0.8), rgba(30, 30, 30, 0.8)), url(${backgroundImage})`,
    backgroundSize: '100%',
    backgroundRepeat: 'repeat',
    height: '100%',
    minHeight: '100vh',
  };

  const cardOuterStyle = {
    width: '100%',
    maxWidth: '1000px',
    padding: '20px',
  };

  const cardContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '40px',
    padding: '20px',
  };

  return (
    <div style={outerWrapperStyle}>
      <div style={cardOuterStyle}>
        <Card title={renderCardTitle()}>
          <div style={cardContentStyle}>
            {!isSinglePage && (
              <Steps
                size="small"
                current={currentStep}
                items={stepItems}
              />
            )}
            {steps[currentStep].content}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CitizenForm;
